import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import PropTypes from "prop-types";

const colors = ["#212121", "#EE6325", "#76CAE2", "#F0C90B", "#F3D4D2"];

const getRandomColor = (lastColor) => {
  let newColor;
  do {
    newColor = colors[Math.floor(Math.random() * colors.length)];
  } while (newColor === lastColor);
  return newColor;
};

const MenuItemImage = ({ item, index, lastColor }) => {
  const ref = useRef(null);
  const [elementTop, setElementTop] = useState(0);
  const [elementHeight, setElementHeight] = useState(0);
  const { scrollY } = useScroll();

  useEffect(() => {
    const handleResize = () => {
      if (ref.current) {
        const rect = ref.current.getBoundingClientRect();
        setElementTop(rect.top + window.scrollY);
        setElementHeight(rect.height);
      }
    };

    handleResize(); // Llama a la función inmediatamente

    window.addEventListener("resize", handleResize); // Añade un listener de resize
    return () => window.removeEventListener("resize", handleResize); // Limpia el listener al desmontar
  }, [item]);

  const translateY = useTransform(
    scrollY,
    [elementTop - window.innerHeight, elementTop - window.innerHeight * 0.35],
    ["100%", "0%"]
  );

  let imageClass;
  let divClass;
  let descClass;
  switch (index % 6) {
    case 0:
      imageClass = "first-image";
      divClass = "first-div";
      descClass = "first-desc";
      break;
    case 1:
      imageClass = "second-image";
      divClass = "second-div";
      descClass = "second-desc";
      break;
    case 2:
      imageClass = "third-image";
      divClass = "third-div";
      descClass = "third-desc";
      break;
    case 3:
      imageClass = "fourth-image";
      divClass = "fourth-div";
      descClass = "fourth-desc";
      break;
    case 4:
      imageClass = "fifth-image";
      divClass = "fifth-div";
      descClass = "fifth-desc";
      break;
    case 5:
      imageClass = "sixth-image";
      divClass = "sixth-div";
      descClass = "sixth-desc";
      break;
    default:
      imageClass = "";
  }

  return (
    <>
      <div>
        <div className={`menu-item-image relative ${divClass}`} ref={ref}>
          <div
            className="background-color"
            style={{ backgroundColor: item.backgroundColor }}
          />
          <motion.div
            className={`image-content ${imageClass}`}
            style={{
              backgroundImage: `url(${item.image})`,
              position: "relative",
              translateY,
            }}
          ></motion.div>
        </div>
        <p className={`bP2 text-left z-[1000] pt-4  ${descClass}`}>
          {item.subtitle}
        </p>
      </div>
    </>
  );
};

const MenuImageReveal = ({ menuCategories, categoryRefs }) => {
  return (
    <>
      {Object.keys(menuCategories).map((category) => (
        <div
          key={category}
          className="menu-category-section"
          ref={categoryRefs[category]}
          data-category={category}
        >
          <div className="menu-images-grid pt-10">
            {menuCategories[category].map((item, index) => (
              <MenuItemImage
                key={index}
                item={item}
                index={index}
                lastColor={index > 0 ? getRandomColor(colors) : null} // Pasar el color anterior
              />
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

MenuImageReveal.propTypes = {
  menuCategories: PropTypes.object.isRequired,
  categoryRefs: PropTypes.object.isRequired,
};

export default MenuImageReveal;
