import React from "react";
import FooterBg from "../Assets/Footer_bg.png";
import { ReactSVG } from "react-svg";
import FooterLogo from "../Assets/BorjaBlackLogo.svg";
import TikTokIcon from "../Assets/Icons/tiktok.svg";
import InstagramIcon from "../Assets/Icons/Instagram.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div
        className="bg-cover bg-center h-[250px] md:h-[570px]"
        style={{
          backgroundImage: `url(${FooterBg})`,
        }}
      ></div>
      <div className="mx-auto mt-16 md:mt-0">
        <div className="md:h-[220px] mx-auto flex flex-col md:flex-row justify-between items-center max-w-[1233px] ">
          <div className="md:w-[33%] flex flex-col items-center md:items-start ">
            <ReactSVG
              src={FooterLogo}
              className="hidden md:block cursor-pointer"
              onClick={() => (window.location.href = "/")}
            />
            <p className="footer-text text-center block md:hidden">
              Lunes a Viernes - 8.30 a 20.00
            </p>
            <p className="footer-text text-center block md:hidden">
              Sábado y Domingo - 9.00 a 20.00
            </p>
            <p className="footer-text text-center block md:hidden">
              Feriados - 9.30 a 20.00
            </p>
          </div>
          <div className="md:w-[33%] flex flex-col items-left my-16 my:mb-0">
            <p className="footer-text hidden md:block">
              Lunes a Viernes - 8.30 a 20.00
            </p>
            <p className="footer-text hidden md:block">
              Sábado y Domingo - 9.00 a 20.00
            </p>
            <p className="footer-text hidden md:block">
              Feriados - 9.30 a 20.00
            </p>
            <ReactSVG
              src={FooterLogo}
              className="block md:hidden "
              onClick={() => (window.location.href = "/")}
            />
          </div>
          <div className="md:w-[33%] flex flex-col items-end mb-16 md:mb-0">
            <div className="flex flex-row items-center">
              <a href="https://instagram.com/borjacafe" target="_blank">
                <ReactSVG src={InstagramIcon} />{" "}
              </a>

              <a href="https://www.tiktok.com/@borjacafe" target="_blank">
                <ReactSVG src={TikTokIcon} className="ml-10" />
              </a>
            </div>
          </div>
        </div>
        <a href="https://labba.studio" target="_blank">
          <p className="made text-center mb-[64px]">Hecho por Labba Studio</p>
        </a>
      </div>
    </>
  );
};

export default Footer;
