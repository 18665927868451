import React from "react";
import CoffeeBg from "../Assets/coffee-bg.webp";

const MobCofeee = () => {
  return (
    <>
      <div id="cafe-especialidad">
        <div className="sticky top-0 z-10 mt-[130px]">
          <div className="flex justify-center ">
            <div
              className="bg-cover bg-center"
              style={{
                backgroundImage: `url(${CoffeeBg})`,
                height: "100vh",
                width: `100vw`,
                position: "relative",
                overflow: "hidden",
                backgroundPosition: "0px 65%",
              }}
            >
              <h2 className="bH1 z-20 absolute text-bBeige left-[25px] sm:left-[88px] mt-[100px] w-[280px] sm:w-auto ">
                ¿Por qué café de <br /> especialidad?
              </h2>
            </div>
          </div>
        </div>

        <div
          className="z-20 relative flex flex-col items-end mt-[0] mx-[25px] sm:mr-[233px] min-h-[calc(100vh - 50px)]"
          style={{
            transform: "translateZ(0)",
            minHeight: "calc(100vh - 230px)",
          }}
        >
          <p className="bP2 text-bBeige sm:w-[445px] mb-[5vh]">
            Hace tiempo que la gente redescubrió el café, entendiendo que hay
            otra forma de consumirlo. El de especialidad no es un tipo o
            variedad de café especifico, sino que es una nueva forma de
            entenderlo, vivirlo y disfrutarlo.
          </p>
          <p className="bP2 text-bBeige sm:w-[445px] mb-[5vh]">
            El café de especialidad es una filosofía que valora todo el proceso,
            desde los trabajadores en la finca hasta el barista que prepara la
            bebida.
          </p>
          <p className="bP2 text-bBeige sm:w-[445px]">
            Borja nace en 2020 con el objetivo de ofrecer buen café y de hacer
            honor a esta nueva cultura, en donde la experiencia es totalmente
            superadora a lo que hasta ahora conocíamos. Nuestro objetivo
            principal es brindar un café de altísima calidad con un menú acorde
            que acompañe y complemente cada una de las sensaciones.
          </p>
        </div>

        {/* <div
          className="z-20 relative flex flex-col items-end mt-[0] mx-[25px] sm:mr-[233px] h-[600px]"
          style={{ transform: "translateZ(0)"
          minHeight: 'calc(100vh - 50px)',
           }}
        >
          <p className="bP2  text-bBeige sm:w-[445px] ">
            Hace tiempo que la gente redescubrió el café, entendiendo que hay
            otra forma de consumirlo. El de especialidad no es un tipo o
            variedad de café especifico, sino que es una nueva forma de
            entenderlo, vivirlo y disfrutarlo.
          </p>
          <br />
          <br />
          <p className="bP2  text-bBeige sm:w-[445px] ">
            El café de especialidad es una filosofía que valora todo el proceso,
            desde los trabajadores en la finca hasta el barista que prepara la
            bebida.
          </p>
          <br />
          <br />
          <p className="bP2  text-bBeige sm:w-[445px] ">
            Borja nace en 2020 con el objetivo de ofrecer buen café y de hacer
            honor a esta nueva cultura, en donde la experiencia es totalmente
            superadora a lo que hasta ahora conocíamos. Nuestro objetivo
            principal es brindar un café de altísima calidad con un menú acorde
            que acompañe y complemente cada una de las sensaciones.
          </p>
        </div> */}
      </div>
    </>
  );
};

export default MobCofeee;
