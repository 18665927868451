import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import Image1 from "../Assets/Prensa/Prensa-M-0.png";
import Image2 from "../Assets/Prensa/Prensa-M-1.png";
import Image3 from "../Assets/Prensa/Prensa-M-2.png";
import Image4 from "../Assets/Prensa/Prensa-M-3.png";
import Image5 from "../Assets/Prensa/Prensa-M-4.png";
import Image6 from "../Assets/Prensa/Prensa-M-5.png";
import Image7 from "../Assets/Prensa/Prensa-M-6.png";
import Image8 from "../Assets/Prensa/Prensa-M-7.png";
import Image9 from "../Assets/Prensa/Prensa-M-8.png";
import Image10 from "../Assets/Prensa/Prensa-M-9.png";
import Image11 from "../Assets/Prensa/Prensa-M-10.png";
import Logo1 from "../Assets/Prensa/Logos/Prensa-L-0.svg";
import Logo2 from "../Assets/Prensa/Logos/Prensa-L-1.svg";
import Logo3 from "../Assets/Prensa/Logos/Prensa-L-2.svg";
import Logo4 from "../Assets/Prensa/Logos/Prensa-L-3.svg";
import Logo5 from "../Assets/Prensa/Logos/Prensa-L-4.svg";
import Logo6 from "../Assets/Prensa/Logos/Prensa-L-5.svg";
import Logo7 from "../Assets/Prensa/Logos/Prensa-L-6.svg";
import Logo8 from "../Assets/Prensa/Logos/Prensa-L-7.svg";
import Logo9 from "../Assets/Prensa/Logos/Prensa-L-8.svg";
import Logo10 from "../Assets/Prensa/Logos/Prensa-L-9.svg";
import ArrowLeft from "../Assets/Prensa/arrow-left.svg";
import ArrowRight from "../Assets/Prensa/arrow-right.svg";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";

const Carousel = () => {
  let slidesNumber = 3;

  if (window.innerWidth <= 650) {
    slidesNumber = 1;
  } else if (window.innerWidth <= 915) {
    slidesNumber = 2;
  }

  const swiperRef = useRef(null);

  const items = [
    {
      id: 3,
      link: "https://www.instagram.com/p/C9xz4aYp4JF/",
      src: Image3,
      logo: Logo3,
      desc: "“Franco Colapinto se sube a un simulador de carreras para competir contra el público”",
    },

    {
      id: 2,
      link: "https://www.instagram.com/reel/Cs9ZT-8ryIF/",
      src: Image2,
      logo: Logo2,
      desc: "“Dos amigos que se sientan a tomar un café y cranean su propia marca”",
    },
    {
      id: 4,
      link: "https://www.instagram.com/tv/CPbUR0TjBD9",
      src: Image4,
      logo: Logo4,
      desc: "Entrevista Canal 7",
    },
    {
      id: 1,
      link: "https://www.instagram.com/reel/DAl9K8vyLrr/",
      src: Image1,
      logo: Logo1,
      desc: "Día del café. Celebralo en @borjacafe y con un 20% Off. ✨",
    },
    {
      id: 5,
      link: "https://www.cucinare.tv/2022/08/05/cande-molfese-abrio-su-primera-cafeteria-en-compania-de-su-nuevo-y-famoso-amor/",
      src: Image5,
      logo: Logo5,
      desc: "Cande Molfese abrió su primera cafetería",
    },
    {
      id: 6,
      link: "https://www.lanacion.com.ar/revista-ohlala/coffe-lovers-recorrido-para-conocer-nuevas-propuestas-baristas-y-otras-joyitas-nid11052021/",
      src: Image6,
      logo: Logo6,
      desc: "“¿Un cafecito?”, debe ser una de las preguntas más frecuentes.",
    },
    {
      id: 7,
      link: "https://www.pagina12.com.ar/383564-sali",
      src: Image7,
      logo: Logo7,
      desc: "“La esquina de Juramento y Moldes se transformó a partir de la apertura de Borja Café”",
    },
    {
      id: 9,
      link: "https://www.lanacion.com.ar/sociedad/de-paseo-por-recoleta-nid23022024/",
      src: Image9,
      logo: Logo9,
      desc: "“¿qué tal un café de especialidad con algo dulce? Borja Specialty Coffee es el lugar perfecto”",
    },
    {
      id: 8,
      link: "https://www.infobae.com/tendencias/2020/09/20/a-veces-las-crisis-dan-oportunidades-historias-de-restaurantes-que-abrieron-en-plena-pandemia/",
      src: Image8,
      logo: Logo8,
      desc: "“A veces las crisis dan oportunidades”: historias de restaurantes que abrieron en plena pandemia",
    },
    {
      id: 10,
      link: "https://www.infogastronomica.com.ar/abrio-borja-cafe/",
      src: Image10,
      logo: Logo10,
      desc: "Abrió @borjacafe y ya enamoró a todo Belgrano",
    },
    {
      id: 11,
      link: "https://www.lanacion.com.ar/sociedad/4-lugares-para-tomar-cafe-frio-nid25012024/",
      src: Image11,
      logo: Logo9,
      desc: "4 lugares para tomar café frío",
    },
  ];

  return (
    <>
      <div className="max-w-[1260px] mx-auto">
        <h2 className="bH1 mt-[100px] lg:mt-[200px] mb-[80px] ml-6 md:ml-0">
          Prensa
        </h2>
      </div>
      <div
        className="carousel-container max-w-[1000px] mb-[50px] lg:mb-[180px] mx-auto relative"
        // style={{ marginBottom: "180px" }}
      >
        <div className=" hidden md:flex flex-row  justify-end mr-6 cursor-pointer">
          <ReactSVG src={ArrowLeft} className=" prev-icon pb-10" />
          <ReactSVG src={ArrowRight} className="ml-6 next-icon pb-10" />
        </div>
        <div>
          <Swiper
            ref={swiperRef}
            modules={[Navigation, Autoplay]}
            spaceBetween={30}
            slidesPerView={slidesNumber}
            loop={true}
            autoplay={{
              delay: 3500,
              disableOnInteraction: false,
            }}
            navigation={{ nextEl: ".next-icon", prevEl: ".prev-icon" }}
          >
            {items.map((item) => (
              <SwiperSlide key={item.id}>
                <Link to={item.link} target="_blank">
                  <div className="flex flex-col items-start  h-[350px] w-[275px]">
                    <div
                      className="w-[275px] h-[205px] "
                      style={{
                        backgroundImage: `url(${item.src})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    <img
                      src={item.logo}
                      alt={item.desc}
                      className="w-[] my-[16px]	"
                    />
                    <p className="mt-2 text-base text-left">{item.desc}</p>
                  </div>
                </Link>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className=" flex md:hidden flex-row  justify-center mt-10 pointer">
          <ReactSVG src={ArrowLeft} className=" prev-icon pb-10" />
          <ReactSVG src={ArrowRight} className="ml-6 next-icon pb-10" />
        </div>
        {/* <div className="arrows-container hidden md:flex ">
          <div className="next ml-2  pointer z-[100]">
            <ReactSVG src={LeftArrow} />
          </div>
          <div className="prev prev-button z-[100]">
            <ReactSVG src={RightArrow} alt="Next" />
          </div>
        </div> */}
      </div>
    </>
  );
};

export default Carousel;
