import Belgrano from "../Assets/ImageSection/Belgrano.png";
import Palermo from "../Assets/ImageSection/Palermo.jpeg";
import Recoleta from "../Assets/ImageSection/Recoleta.webp";
import Valencia from "../Assets/ImageSection/Valencia.png";

const locations = {
  Belgrano: {
    text: "Nuestro primer local en Juramento y Vidal. Abrimos en agosto 2020 ofreciendo una nueva experiencia, que fue elegida rápidamente por la gente del barrio y alrededores. Nuestra propuesta fue ofrecer un café distinto al conocido hasta ese momento, resaltando la materia prima de alta calidad y una pastelería de excelencia.",
    image: Belgrano,
    locationText: "Av. Juramento 2800",
    locationLink: "https://maps.app.goo.gl/b7nNBRmyJovJCX9T9",
  },
  Palermo: {
    text: "En agosto 2022 de la mano de Cande Molfese (Actriz y cantante), llegó nuestro segundo local con una propuesta y una ubicación única, en Palermo frente al Ecoparque. Gracias a la repercusión en el barrio y en los medios de comunicación, logramos posicionar la marca y consolidarnos. Con una nueva impronta, vegetariana pero siempre con el café como protagonista, logramos instalar nuevas opciones y prácticas basadas en el cuidado del medio ambiente y la conciencia sustentable (leches vegetales y agua filtrada sin costo adicional, descuentos en el take away para quienes traen su taza, más productos gluten free, eco mixers compostables, local pet friendly, entre otros).",
    image: Palermo,
    locationText: "República de la India 2895",
    locationLink: "https://maps.app.goo.gl/R8XvW1ew8KDyXEZg9",
  },
  Recoleta: {
    text: "Con tres años de experiencia y siendo una marca reconocida, llegó nuestro tercer local en julio 2023. Logramos plasmar todo lo aprendido para brindar una propuesta superadora para un barrio icónico de la Ciudad de Buenos Aires.",
    image: Recoleta,
    locationText: "Av. Juncal 2303",
    locationLink: "https://maps.app.goo.gl/UC2mkh2YGrJgCwrC7",
  },
  Valencia: {
    text: "SIN FRONTERAS. Luego de mucho esfuerzo cumplimos un sueño impensado, pasar de ser una pequeña cafetería de especialidad en Belgrano, a abrir nuestra primera sucursal en el exterior en 2024. En pleno auge y crecimiento del concepto “Specialty Coffee” en España llegamos para replicar nuestra esencia en el mercado internacional.",
    image: Valencia,
    locationText: "Vicente Beltrán Grimal, 23",
    locationLink: "https://maps.app.goo.gl/UyFfNgSmSrNqNF5v8",
  },
};
export default locations;
