import React from "react";
import Image1 from "../Assets/ImageSection/Instagram/instagram-1.png";
import Image2 from "../Assets/ImageSection/Instagram/instagram-2.png";
import Image3 from "../Assets/ImageSection/Instagram/instagram-3.png";
import Image4 from "../Assets/ImageSection/Instagram/instagram-4.png";
import Image5 from "../Assets/ImageSection/Instagram/instagram-5.png";
import { ReactSVG } from "react-svg";
import IconNav from "../Assets/IconNavbar.svg";

import InstaLogo from "../Assets/ImageSection/Instagram/InstaLogo.svg";
const InstaSectionMob = () => {
  return (
    <div className="mx-6  lg:my-[100px]">
      <div className="  ">
        <div className="flex justify-center items-center mb-20">
          <a
            href="https://instagram.com/borjacafe"
            target="_blank"
            className="flex items-center justify-end mb-4 bH1"
          >
            @borjacafe
            <ReactSVG src={InstaLogo} className="ml-2" />
          </a>
        </div>

        <div className="flex justify-end items-center ">
          <img src={Image2} width={200} height={200} />
        </div>

        {/* <div className="flex justify-start items-center my-8">
          <img src={Image5} width={200} height={200} />
        </div> */}
        {/* Image 4 - Centered */}
        <div className="flex justify-start items-center my-8">
          <img src={Image4} width={200} height={200} />
        </div>

        {/* Image 5 - Aligned Right */}
        <div className="flex justify-end items-center my-8 ">
          <img src={Image1} width={200} height={200} />
        </div>

        {/* Image 6 - Centered */}
        <div className="flex justify-start items-center ">
          <img src={Image3} width={200} height={200} />
        </div>
      </div>
    </div>
  );
};

export default InstaSectionMob;
