import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import SpeCoffee from "../Assets/foto+sticker.webp";
import { AnimatePresence, motion, useInView } from "framer-motion";
import ImagesDemo from "./ImagesDemo";
import StaggeredText from "../Hooks/StaggeredText";

const AboutUs = () => {
  const ref = React.useRef(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [startAnimation, setStartAnimation] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleScroll = () => {
    const top = ref.current.getBoundingClientRect().top;

    // Cambia la visibilidad basándote en la posición del scroll
    if (top < -620) {
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  let speWidth = isMobile ? 75 : 144;
  let text = "El comienzo";
  return (
    <>
      <div id="sobre-borja"></div>
      <div className="relative mt-[80px] sm:mt-[125px]" ref={ref}>
        <div style={{ position: "sticky", top: "100px", left: "7px" }}>
          <motion.div
            initial={{ opacity: 0 }} // Comienza invisible
            animate={isVisible ? { opacity: 1 } : { opacity: 0 }} // Cambia a opacidad 0 si no está visible
            exit={{ opacity: 0 }} // Animación de salida
            transition={{ duration: 0.8 }}
          >
            <div className="sm:text-center ml-[35px] sm:ml-0">
              <StaggeredText text={"El comienzo"} />
            </div>
            <div>
              <div className=" flex justify-around max-w-[450px] sm:max-w-full mx-auto md:max-w-[930px] mt-10">
                <div className="hidden md:block relative w-1/2">
                  <img src={SpeCoffee} width={330} className="mt-[-60px]" />
                </div>

                <div className="mx-6 sm:mx-0 max-w-[360px] med:max-w-full md:w-1/2 sm:mr-0 mb-12">
                  <p className="mr-0 ssm:mr-5 sm:pr-0 text-lg	leading-6	">
                    Desde que abrimos nuestro primer local en Belgrano, nos
                    comprometimos a ofrecer una propuesta diferente basada en
                    productos de alta calidad. Centrándonos en preparar un café
                    excepcional, rápidamente logramos posicionarnos entre las
                    cafeterías de especialidad más elegidas del barrio, y luego,
                    en distintos puntos de la ciudad.
                  </p>

                  <p className="mr-0 ssm:mr-0 mt-6 sm:pr-0 text-lg	leading-6">
                    En Borja, no solo se trata de disfrutar del mejor café, sino
                    también de compartir experiencias, crear vínculos y sentirse
                    parte de una comunidad que valora los pequeños detalles que
                    hacen la diferencia.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
        <ImagesDemo />
      </div>
    </>
  );
};

export default AboutUs;
