import React, { useState, useEffect, useRef } from "react";
import "../Styles/Menu.css";
import MenuImageReveal from "../Hooks/MenuImageReveal";
import menuCategories from "../Hooks/MenuData";

function Menu({ lenisInstance }) {
  const [activeCategory, setActiveCategory] = useState("Pasteleria");
  const [borderPosition, setBorderPosition] = useState(0);
  const categoryRefs = {
    Pasteleria: useRef(null),
    Bebidas: useRef(null),
    Almuerzo: useRef(null),
  };
  const [userClicked, setUserClicked] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !userClicked) {
            const newCategory = entry.target.getAttribute("data-category");
            if (newCategory !== activeCategory) {
              setActiveCategory(newCategory);
              const index = Object.keys(categoryRefs).indexOf(newCategory);
              setBorderPosition(index * 65);
            }
          }
        });
      },
      { threshold: 0.1 }
    );

    Object.values(categoryRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(categoryRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, [userClicked, activeCategory]);

  const scrollToCategory = (category) => {
    const index = Object.keys(categoryRefs).indexOf(category);
    setBorderPosition(index * 65); // Cambia la posición del borde de forma instantánea

    if (categoryRefs[category].current) {
      const targetPosition = categoryRefs[category].current.offsetTop;

      // Utiliza Lenis para hacer el desplazamiento suave
      lenisInstance.scrollTo(targetPosition, {
        duration: 1,
        easing: (t) => t,
      });
    }

    setActiveCategory(category); // Este cambio es instantáneo

    setUserClicked(true); // Establece que se hizo clic

    // Restablece el estado después de un corto período
    setTimeout(() => {
      setUserClicked(false);
    }, 1000); // Un tiempo breve para que el observer pueda reaccionar nuevamente
  };

  return (
    <>
      <div className="px-[30px]" id="menu-section">
        <div className="menu-container max-w-[1290px] mx-auto ">
          <div className="menu-scroll ">
            <div className=" mb-20">
              <h2 className="bH1">Menú</h2>
            </div>
            <div className="relative">
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  top: borderPosition,
                  height: "60px",
                  borderLeft: "solid #212121 4px",
                  opacity: 1,
                  transition: "top 500ms ease, opacity 500ms ease",
                }}
              />
              {Object.keys(categoryRefs).map((category) => (
                <div
                  key={category}
                  ref={categoryRefs[category]}
                  data-category={category}
                  className={`menu-item-scroll ${
                    activeCategory === category ? "active" : ""
                  }`}
                  onClick={() => scrollToCategory(category)}
                >
                  <h2
                    className={`text-menu pl-[30px] ${
                      activeCategory === category ? "text-active" : ""
                    }`}
                  >
                    {category}
                  </h2>
                </div>
              ))}
            </div>
            <a
              href="/menu-borja.pdf"
              download="menu-borja.pdf"
              rel="noopener noreferrer"
              className="group"
            >
              <div className="justify-center items-center relative overflow-hidden mt-10">
                <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:-translate-y-16">
                  <span className="text-xl leading-7 underline">
                    Descargá nuestro menú
                  </span>
                </div>

                <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-y-[-84px]  bottom-[-80px]">
                  <span className="text-xl leading-7 underline">
                    Descargá nuestro menú
                  </span>
                </div>
              </div>
            </a>
          </div>

          <div className="menu-content">
            <MenuImageReveal
              menuCategories={menuCategories}
              categoryRefs={categoryRefs}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
