import React, { useEffect, useState } from "react";
import "../Styles/Navbar.css";
import IconNav from "../Assets/IconNavbar.svg";
import Logo from "../Assets/BorjaBlackLogo.svg";
import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";

const Navbar = ({ lenisInstance }) => {
  const [navbarVisible, setNavbarVisible] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0); // Guardar la última posición de scroll
  const [isChecked, setIsChecked] = useState(false);

  const [scrollPos, setScrollPos] = useState(window.scrollY);
  const [passedHeroRange, setPassedHeroRange] = useState(false); // Para saber si se superó el rango del hero

  // Detecta el tamaño del hero para desktop y mobile
  const getHeroHeight = () => {
    if (window.innerWidth >= 768) {
      // Desktop
      return 105; // 105vh en desktop
    } else {
      // Mobile
      return 80; // 80vh en mobile
    }
  };

  // Función para manejar la visibilidad de la navbar
  const handleVisibility = (currentScrollPos) => {
    const heroHeight = getHeroHeight();
    const heroHeightPx = (window.innerHeight * heroHeight) / 100; // Convertimos a píxeles
    const additionalScrollRange = 500; // Rango en píxeles donde la navbar se mantiene visible después de superar el umbral

    // Si estamos en el rango del hero, la navbar no debe ser visible
    if (currentScrollPos < heroHeightPx) {
      setNavbarVisible(false);
      setPassedHeroRange(false); // No hemos pasado el rango del hero
    }
    // Si hemos pasado el hero, la navbar debe mostrarse durante 500px adicionales
    else if (
      currentScrollPos > heroHeightPx &&
      currentScrollPos <= heroHeightPx + additionalScrollRange
    ) {
      setNavbarVisible(true);
      setPassedHeroRange(true); // Ahora estamos en el rango donde la navbar debería estar visible
    }
    // Después de esos 500px adicionales, la navbar vuelve a la lógica normal
    else if (currentScrollPos > heroHeightPx + additionalScrollRange) {
      if (currentScrollPos > scrollPos) {
        setNavbarVisible(false); // Scroll hacia abajo
        setIsChecked(false);
      } else {
        setNavbarVisible(true); // Scroll hacia arriba
        setIsChecked(false);
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;

      // Llama a la función para manejar la visibilidad
      handleVisibility(currentScrollPos);

      // Actualizamos la posición del scroll
      setScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollPos]);

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();

    const section = document.getElementById(sectionId);
    if (section && lenisInstance) {
      lenisInstance.scrollTo(section);
      closeMenu();
    }
  };

  const closeMenu = () => {
    const drawerInput = document.getElementById("drawer-input");
    if (drawerInput) {
      drawerInput.checked = false;
    }
  };

  useEffect(() => {
    const handleOutsideClick = (e) => {
      const header = document.querySelector(".header");
      const groupItems = document.querySelectorAll(".group"); // Seleccionamos todos los elementos con la clase .group

      // Si el clic es fuera del header y los items con la clase .group, cierra el menú
      if (header && !header.contains(e.target)) {
        closeMenu();
        setIsChecked(false);
      } else {
        // Si el clic ocurre en alguno de los elementos .group, también se cierra el menú
        groupItems.forEach((item) => {
          if (item.contains(e.target)) {
            closeMenu();
            setIsChecked(false);
          }
        });
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  return (
    <>
      <div
        className={
          navbarVisible
            ? "header navbar navbar-visible"
            : " header navbar navbar-hidden"
        }
      >
        <input
          className="hidden"
          id="drawer-input"
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)} // Cambia el estado al hacer clic
        />

        <ReactSVG
          onClick={() => (window.location.href = "/")}
          className="logo-header cursor-pointer"
          src={Logo}
          beforeInjection={(svg) => {
            svg.setAttribute(
              "style",
              "width: 130px; z-index: 1000; margin-left: 25px"
            );
          }}
        />

        <label htmlFor="drawer-input" className="drawer-open">
          <span></span>
        </label>

        <nav className="nav-content">
          <div className="items">
            <div
              className={`${
                isChecked ? "cursor-pointer" : "cursor-default"
              }  group border-y border-[#A5A39F] lg:border-r lg:border-bBlack lg:border-y-0 h-[90px] lg:h-[100%] lg:w-[25%] flex lg:items-end items-center justify-between  px-[18px] lg:px-6 lg:pb-7 hover:bg-bYellow lg:rounded-bl-[9px] lg:rounded-tl-[9px]`}
              onClick={(e) => {
                const inputChecked =
                  document.getElementById("drawer-input").checked;
                if (inputChecked) {
                  scrollToSection(e, "sobre-borja");
                }
              }}
            >
              <span>
                <div className="flex justify-center items-center   relative overflow-hidden">
                  <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:-translate-y-16">
                    <span className="">Sobre Borja</span>
                  </div>
                  <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-y-[-84px]  bottom-[-80px]">
                    <span className="">Sobre Borja</span>
                  </div>
                </div>
              </span>
              <span>
                <div className="w-[44px] h-[44px] rounded-full	 border border-black border-solid flex justify-center items-center   relative overflow-hidden">
                  <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-16 sm:group-hover:-translate-y-16">
                    <ReactSVG src={IconNav} className="" />
                  </div>

                  <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-[34px] sm:group-hover:translate-y-[-35px]  bottom-[-35px] left-[-35px]">
                    <ReactSVG src={IconNav} className="" />
                  </div>
                </div>
              </span>
            </div>
            <div
              className={`${
                isChecked ? "cursor-pointer" : "cursor-default"
              } group h-[90px] lg:h-[100%] lg:w-[25%] flex lg:items-end items-center justify-between  px-[18px] lg:px-6 lg:pb-7 hover:bg-bPink`}
              onClick={(e) => {
                const inputChecked =
                  document.getElementById("drawer-input").checked;
                if (inputChecked) {
                  scrollToSection(e, "cafe-especialidad");
                }
              }}
            >
              <span>
                <div className="flex justify-center items-center   relative overflow-hidden">
                  <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:-translate-y-[5rem] mt-[2px]">
                    <span className="">
                      Café de <br className="hidden lg:block" />{" "}
                      especialidad&nbsp;
                    </span>
                  </div>

                  <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-y-[-84px]  bottom-[-80px]">
                    <span className="">
                      Café de <br className="hidden lg:block" />{" "}
                      especialidad&nbsp;
                    </span>
                  </div>
                </div>
              </span>
              <span>
                <div className="w-[44px] h-[44px] rounded-full	 border border-black border-solid flex justify-center items-center   relative overflow-hidden">
                  <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-16 sm:group-hover:-translate-y-16">
                    <ReactSVG src={IconNav} className="" />
                  </div>

                  <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-[34px] sm:group-hover:translate-y-[-35px]  bottom-[-35px] left-[-35px]">
                    <ReactSVG src={IconNav} className="" />
                  </div>
                </div>
              </span>
            </div>
            <div
              className={`${
                isChecked ? "cursor-pointer" : "cursor-default"
              } group border-y border-[#A5A39F] lg:border-x lg:border-bBlack lg:border-y-0 h-[90px] lg:h-[100%] lg:w-[25%] flex lg:items-end items-center justify-between  px-[18px] lg:px-6 lg:pb-7 hover:bg-bOrange `}
              onClick={(e) => {
                const inputChecked =
                  document.getElementById("drawer-input").checked;
                if (inputChecked) {
                  scrollToSection(e, "donde-estamos");
                }
              }}
            >
              <span>
                <div className="flex justify-center items-center   relative overflow-hidden">
                  <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:-translate-y-16">
                    <span className="">Dónde estamos&nbsp;</span>
                  </div>

                  <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-y-[-84px]  bottom-[-80px]">
                    <span className="">Dónde estamos&nbsp;</span>
                  </div>
                </div>
              </span>
              <span>
                <div className="w-[44px] h-[44px] rounded-full	 border border-black border-solid flex justify-center items-center   relative overflow-hidden">
                  <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-16 sm:group-hover:-translate-y-16">
                    <ReactSVG src={IconNav} className="" />
                  </div>

                  <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-[34px] sm:group-hover:translate-y-[-35px]  bottom-[-35px] left-[-35px]">
                    <ReactSVG src={IconNav} className="" />
                  </div>
                </div>
              </span>
            </div>
            <div
              className={`${
                isChecked ? "cursor-pointer" : "cursor-default"
              }  group h-[90px] lg:h-[100%] lg:w-[25%] flex lg:items-end items-center justify-between  px-[18px] lg:px-6 lg:pb-7 hover:bg-bSky rounded-br-[9px] rounded-tr-[9px]`}
              onClick={(e) => {
                const inputChecked =
                  document.getElementById("drawer-input").checked;
                if (inputChecked) {
                  scrollToSection(e, "menu-section");
                }
              }}
            >
              <span>
                <div className="flex justify-center items-center   relative overflow-hidden">
                  <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:-translate-y-16">
                    <span className="">Menú</span>
                  </div>

                  <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-y-[-84px]  bottom-[-80px]">
                    <span className="">Menú</span>
                  </div>
                </div>
              </span>
              <span>
                <div className="w-[44px] h-[44px] rounded-full	 border border-black border-solid flex justify-center items-center   relative overflow-hidden">
                  <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-16 sm:group-hover:-translate-y-16">
                    <ReactSVG src={IconNav} className="" />
                  </div>

                  <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-[34px] sm:group-hover:translate-y-[-35px]  bottom-[-35px] left-[-35px]">
                    <ReactSVG src={IconNav} className="" />
                  </div>
                </div>
              </span>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
