import React from "react";
import { useRef, useState } from "react";
import VideoHero from "../Assets/Videos/HeroVideo.mp4";
import HeroImg from "../Assets/coffee-bg.webp";
import BorjaWhiteLogo from "../Assets/BorjaWhiteLogo.svg";
const HeroVideo = ({ onVideoLoaded }) => {
  let isDesktop = window.innerWidth > 1024;

  const videoContainerRef = useRef(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const videoRef = useRef(null); // Referencia para el video

  let autoPlayTrueOrFalse = false;

  if (!isDesktop) {
    autoPlayTrueOrFalse = true;
  }
  return (
    <div
      className="h-[80vh] w-[100vw] med:h-[105vh] relative overflow-hidden"
      ref={videoContainerRef}
    >
      <video
        ref={videoRef}
        className="absolute top-0 left-0 w-full h-full object-cover"
        loop
        muted
        playsInline
        autoPlay={autoPlayTrueOrFalse}
        preload="auto"
        onLoadedData={() => {
          setVideoLoaded(true); // El video ha cargado y marcamos el estado como true
          onVideoLoaded(); // Notificamos al Loader que el video ha cargado y empezado

          setTimeout(() => {
            videoRef.current.play(); // Inicia la reproducción del video después del retraso
          }, 1750); // Retraso de 1700ms
        }}
        onError={(e) => {
          e.currentTarget.style.display = "none";
        }}
      >
        <source src={VideoHero} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <img
        src={HeroImg}
        alt="Hero Image"
        className="absolute top-0 left-0 w-full h-full object-cover"
        style={{ display: videoLoaded ? "none" : "block" }}
      />
      <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
      <div className="relative z-10 flex items-center justify-center h-full">
        <img src={BorjaWhiteLogo} className="h-auto w-[270px] sm:w-[380px]" />
      </div>
    </div>
  );
};

export default HeroVideo;
