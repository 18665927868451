import React from "react";
import Image1 from "../Assets/ImageSection/Instagram/instagram-1.png";
import Image2 from "../Assets/ImageSection/Instagram/instagram-2.png";
import Image3 from "../Assets/ImageSection/Instagram/instagram-3.png";
import Image4 from "../Assets/ImageSection/Instagram/instagram-4.png";
import Image5 from "../Assets/ImageSection/Instagram/instagram-5.png";

const InstaSection = () => {
  return (
    <div className="new-section max-w-[1140px] mx-auto h-[765px] grid grid-cols-3 gap-4 mt-[200px]">
      <div className="relative h-full col-span-3 grid grid-cols-3 grid-rows-3 gap-16">
        {/* Image 1 - Centered */}
        <div className="flex justify-center items-center">
          <img
            src={Image1}
            width={281}
            height={281}
            style={{ transform: "translateY(0px) translateX(0px)" }}
          />
        </div>

        {/* Image 2 - Aligned Right */}
        <div className="flex justify-end items-center">
          <img
            src={Image2}
            width={281}
            height={281}
            style={{ transform: "translateY(80px) translateX(0px)" }}
          />
        </div>

        <a
          href="https://instagram.com/borjacafe"
          target="_blank"
          className="flex items-center justify-end mb-4 bH1 group"
        >
          @borjacafe
          <div className="w-[68px] ml-[20px] h-[68px] bg-bPink rounded-full border border-transparent border-solid flex justify-center items-center   relative overflow-hidden">
            <div className="flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-16 sm:group-hover:-translate-y-16">
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 20L21 1M21 1H3.22222M21 1V17.8889"
                  stroke="#212121"
                />
              </svg>
            </div>

            <div className="absolute flex items-center transition-transform duration-500 ease-in-out transform sm:group-hover:translate-x-[47px] sm:group-hover:translate-y-[-47px]  bottom-[-25px] left-[-25px]">
              <svg
                width="22"
                height="21"
                viewBox="0 0 22 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 20L21 1M21 1H3.22222M21 1V17.8889"
                  stroke="#212121"
                />
              </svg>
            </div>
          </div>
        </a>
        {/* Image 4 - Centered */}
        <div className="flex justify-center items-center">
          <img
            src={Image5}
            width={281}
            height={281}
            style={{ transform: "translateY(0px) translateX(60px)" }}
          />
        </div>

        {/* Image 5 - Aligned Right */}
        <div className="flex justify-end items-center">
          <img
            src={Image4}
            width={281}
            height={281}
            style={{ transform: "translateY(80px) translateX(60px)" }}
          />
        </div>

        {/* Image 6 - Centered */}
        <div className="flex justify-end items-end">
          <img
            src={Image3}
            width={281}
            height={281}
            style={{ transform: "translateY(-50px) translateX(0px)" }}
          />
        </div>
      </div>
    </div>
  );
};

export default InstaSection;
