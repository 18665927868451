import Pasteleria1 from "../Assets/ImageSection/Pasteleria/Pasteleria1.webp";
import Pasteleria3 from "../Assets/ImageSection/Pasteleria/Pasteleria3.webp";
import Pasteleria4 from "../Assets/ImageSection/Pasteleria/Pasteleria4.webp";
import Pasteleria5 from "../Assets/ImageSection/Pasteleria/Pasteleria5.webp";
import Pasteleria6 from "../Assets/ImageSection/Pasteleria/Pasteleria6.webp";
import Pasteleria7 from "../Assets/ImageSection/Pasteleria/Pasteleria7.webp";
import Pasteleria8 from "../Assets/ImageSection/Pasteleria/Pasteleria8.webp";
import Pasteleria9 from "../Assets/ImageSection/Pasteleria/Pasteleria9-9.webp";
import Pasteleria10 from "../Assets/ImageSection/Pasteleria/Pasteleria10.webp";
import Almuerzo1 from "../Assets/ImageSection/Almuerzo/Almuerzo1.webp";
import Almuerzo3 from "../Assets/ImageSection/Almuerzo/Almuerzo3.webp";
import Almuerzo4 from "../Assets/ImageSection/Almuerzo/Almuerzo4.webp";
import Almuerzo5 from "../Assets/ImageSection/Almuerzo/Almuerzo5.webp";
import Almuerzo7 from "../Assets/ImageSection/Almuerzo/Almuerzo-New-2.webp";
import Almuerzo8 from "../Assets/ImageSection/Almuerzo/Almuerzo-New-3.webp";
import Almuerzo9 from "../Assets/ImageSection/Almuerzo/Almuerzo-New-4.webp";
import Almuerzo10 from "../Assets/ImageSection/Almuerzo/Almuerzo-New-5.webp";
import Almuerzo11 from "../Assets/ImageSection/Almuerzo/Almuerzo-New-6.webp";
import Almuerzo12 from "../Assets/ImageSection/Almuerzo/Almuerzo-New-7.webp";
import Almuerzo13 from "../Assets/ImageSection/Almuerzo/Almuerzo-New-8.webp";
import Almuerzo14 from "../Assets/ImageSection/Almuerzo/Almuerzo-New-9.webp";
import Bebidas1 from "../Assets/ImageSection/Bebidas/Bebidas1.webp";
import Bebidas2 from "../Assets/ImageSection/Bebidas/Bebidas2.webp";
import Bebidas3 from "../Assets/ImageSection/Bebidas/Bebidas3.webp";
import Bebidas4 from "../Assets/ImageSection/Bebidas/Bebidas-New-1.webp";
import Bebidas5 from "../Assets/ImageSection/Bebidas/Bebidas-New-2.webp";
import Bebidas6 from "../Assets/ImageSection/Bebidas/Bebidas-New-3.webp";
import Bebidas7 from "../Assets/ImageSection/Bebidas/Bebidas-New-4.webp";
import Bebidas8 from "../Assets/ImageSection/Bebidas/Bebidas-New-5.webp";
import Bebidas9 from "../Assets/ImageSection/Bebidas/Bebidas-New-6.webp";
import Bebidas10 from "../Assets/ImageSection/Bebidas/Bebidas-New-7.webp";
import Bebidas11 from "../Assets/ImageSection/Bebidas/Bebidas-New-8.webp";
import Bebidas12 from "../Assets/ImageSection/Bebidas/Bebidas-New-9.webp";
import Bebidas13 from "../Assets/ImageSection/Bebidas/Bebidas-New-10.webp";
import Bebidas14 from "../Assets/ImageSection/Bebidas/Bebidas-New-11.webp";

const menuCategories = {
  Pasteleria: [
    {
      mobileVisibility: "true",
      image: Pasteleria1,
      backgroundColor: "#212121",
      subtitle: "Alfajor de almendras",
    },
    {
      mobileVisibility: "true",
      image: Pasteleria3,
      backgroundColor: "#F0C90B",
      subtitle: "Budín de carrot cake",
    },

    {
      mobileVisibility: "false",
      image: Pasteleria4,
      backgroundColor: "#76CAE2",
      subtitle: "Chipa",
    },
    {
      mobileVisibility: "true",
      image: Pasteleria5,
      backgroundColor: "#EE6325",
      subtitle: "Cookie Rellena",
    },
    {
      mobileVisibility: "true",
      image: Pasteleria7,
      backgroundColor: "#212121",
      subtitle: "Key lime pie",
    },
    {
      mobileVisibility: "false",
      image: Pasteleria6,
      backgroundColor: "#F3D4D2",
      subtitle: "Cookie Rellena",
    },
    {
      mobileVisibility: "false",
      image: Pasteleria8,
      backgroundColor: "#EE6325",
      subtitle: "Mini alfajores",
      // se va de mob
    },
    {
      mobileVisibility: "true",
      image: Pasteleria10,
      backgroundColor: "#F0C90B",
      subtitle: "Spring Chía",
    },
    {
      mobileVisibility: "true",
      image: Pasteleria9,
      backgroundColor: "#76CAE2",
      subtitle: "Roll de Canela",
    },
  ],
  Bebidas: [
    {
      mobileVisibility: "false",
      image: Bebidas1,
      backgroundColor: "#F3D4D2",
      subtitle: "Cappuccino",
      // se va de mob
    },
    {
      mobileVisibility: "true",
      image: Bebidas2,
      backgroundColor: "#212121",
      subtitle: "Cold Brew",
    },
    {
      mobileVisibility: "true",
      image: Bebidas3,
      backgroundColor: "#EE6325",
      subtitle: "Chocolate Caliente",
    },
    {
      mobileVisibility: "false",
      image: Bebidas4,
      backgroundColor: "#F3D4D2",
      subtitle: "Berries iced latte",
      // se va de mob
    },
    {
      mobileVisibility: "true",
      image: Bebidas5,
      backgroundColor: "#212121",
      subtitle: "Iced Flat ",
    },
    {
      mobileVisibility: "true",
      image: Bebidas7,
      backgroundColor: "#76CAE2",
      subtitle: "Flat white ",
    },
    {
      mobileVisibility: "false",
      image: Bebidas6,
      backgroundColor: "#F0C90B",
      subtitle: "Exprimido de naranja ",
      // se va de mob
    },
    {
      mobileVisibility: "true",
      image: Bebidas8,
      backgroundColor: "#EE6325",
      subtitle: "Iced Latte",
    },
    {
      mobileVisibility: "true",
      image: Bebidas9,
      backgroundColor: "#F3D4D2",
      subtitle: "Latte",
    },
    {
      mobileVisibility: "true",
      image: Bebidas10,
      backgroundColor: "#212121",
      subtitle: "Lavanda iced latte ",
    },
    {
      mobileVisibility: "true",
      image: Bebidas11,
      backgroundColor: "#EE6325",
      subtitle: "Limonada menta y jengibre ",
    },
    {
      mobileVisibility: "true",
      image: Bebidas12,
      backgroundColor: "#76CAE2",
      subtitle: "Magic",
    },
    {
      mobileVisibility: "true",
      image: Bebidas13,
      backgroundColor: "#F0C90B",
      subtitle: "Matcha Iced Latte ",
    },
    {
      mobileVisibility: "false",
      image: Bebidas14,
      backgroundColor: "#212121",
      subtitle: "Mocaccino",
      // se va de mob
    },
  ],
  Almuerzo: [
    // {
    // mobileVisibility: "true",
    // image: Almuerzo2,
    //   backgroundColor: "#212121",
    //   subtitle: "Avo toast con huevo",
    // },
    {
      mobileVisibility: "true",
      image: Almuerzo4,
      backgroundColor: "#F0C90B",
      subtitle: "Croissant de palta y huevos revueltos ",
    },
    {
      mobileVisibility: "true",
      image: Almuerzo3,
      backgroundColor: "#76CAE2",
      subtitle: "Bagel de champignon, brie, rúcula",
    },
    {
      mobileVisibility: "true",
      image: Almuerzo1,
      backgroundColor: "#EE6325",
      subtitle: "Avo toas con huevos revueltos",
    },
    {
      mobileVisibility: "true",
      image: Almuerzo5,
      backgroundColor: "#F3D4D2",
      subtitle: "Sopa de calabaza ",
    },
    // {
    // mobileVisibility: "true",
    // image: Almuerzo6,
    //   backgroundColor: "#212121",
    //   subtitle: "Avotoast con huevos Revueltos ",
    // },
    {
      mobileVisibility: "true",
      image: Almuerzo7,
      backgroundColor: "#EE6325",
      subtitle: "Caju toast y Tostado JyQ ",
    },
    {
      mobileVisibility: "true",
      image: Almuerzo9,
      backgroundColor: "#F0C90B",
      subtitle: "Croissant mortadella, muza,rucula, miel, almendras  ",
    },
    {
      mobileVisibility: "true",
      image: Almuerzo8,
      backgroundColor: "#76CAE2",
      subtitle: "Caju toast ",
    },
    {
      mobileVisibility: "false",
      image: Almuerzo10,
      backgroundColor: "#76CAE2",
      subtitle: "Croissant palta huevo tomate ",
    },
    {
      mobileVisibility: "false",
      image: Almuerzo11,
      backgroundColor: "#EE6325",
      subtitle: "Ensalada de huevos revueltos, palta, brie y tomate cherry",
    },
    {
      mobileVisibility: "true",
      image: Almuerzo12,
      backgroundColor: "#F3D4D2",
      subtitle: "Tostado tomate y queso, cappuccino ",
    },
    {
      mobileVisibility: "false",
      image: Almuerzo13,
      backgroundColor: "#212121",
      subtitle: "Toston de berenjenas, tomate y queso de cabra",
    },
    {
      mobileVisibility: "true",
      image: Almuerzo14,
      backgroundColor: "#EE6325",
      subtitle: "Tostón de calabaza ",
    },
  ],
};

export default menuCategories;
