import React, { useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const StaggeredText = ({ text }) => {
  const textRef = useRef(null);
  const [ref, inView] = useInView({
    threshold: 0.5, // El porcentaje visible de la sección que activa la animación
    triggerOnce: true, // Ejecutar solo una vez
  });

  useEffect(() => {
    if (inView) {
      const spans = textRef.current.querySelectorAll("span");
      spans.forEach((span, index) => {
        setTimeout(() => {
          span.style.opacity = 1;
          span.style.transform = "translateY(0)";
        }, index * 70); // Retraso progresivo
      });
    }
  }, [inView]); // Escuchar cambios en la visibilidad

  const splitText = (text) => {
    return text.split("").map((char, index) => (
      <span
        key={index}
        style={{
          opacity: 0,
          transform: "translateY(10px)", // Ajusta el valor inicial
          transition: "opacity 0.7s ease, transform 0.7s ease",
        }}
        className="mr-[-8px] md:mr-[-10px] bH1"
      >
        {char === " " ? "\u00A0" : char} {/* Mantiene espacios */}
      </span>
    ));
  };

  return (
    <h2
      ref={(node) => {
        textRef.current = node;
        ref(node); // Conecta el ref de Intersection Observer
      }}
      className="hero-text"
    >
      {splitText(text)}
    </h2>
  );
};

export default StaggeredText;
