import React from "react";
import Navbar from "../Components/Navbar";
import AboutUs from "../Components/AboutUs";
import Locations from "../Components/Locations";
import Menu from "../Components/Menu";
import CoffeeAbout from "../Components/CoffeeAbout";

import InstaSection from "../Components/InstaSection";
import Prensa from "../Components/Prensa";
import Footer from "../Components/Footer";
import Loader from "../Components/Loader";
import HeroVideo from "../Components/HeroVideo";
import Lenis from "lenis";
import { useState } from "react";
import LocationsMob from "../Components/LocationsMob";
import MenuMob from "../Components/MenuMob";
import CoffeAboutMob from "../Components/CoffeeAboutMob";
import InstaSectionMob from "../Components/InstaSectionMob";
const Home = () => {
  let isDesktop = window.innerWidth > 1024;

  const lenisInstance = new Lenis();

  function raf(time) {
    lenisInstance.raf(time);
    requestAnimationFrame(raf);
  }
  requestAnimationFrame(raf);

  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  return (
    <>
      <Loader isVideoLoaded={isVideoLoaded} />
      <Navbar lenisInstance={lenisInstance} />
      <HeroVideo onVideoLoaded={() => setIsVideoLoaded(true)} />
      <AboutUs />
      {isDesktop && (
        <>
          <CoffeeAbout />
          <Locations />
          <Menu lenisInstance={lenisInstance} />
          <InstaSection />
        </>
      )}

      {!isDesktop && (
        <>
          <CoffeAboutMob />
          <LocationsMob />
          <MenuMob />
          <InstaSectionMob />
        </>
      )}
      <Prensa />
      <Footer />
    </>
  );
};

export default Home;
