import React, { useEffect, useRef, useState } from "react";
import CoffeeBg from "../Assets/coffee-bg.webp";

const CoffeeAbout = () => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = useState(false); // Track visibility
  const [scrollPosition, setScrollPosition] = useState(0); // To track scroll position relative to visibility
  const [width, setWidth] = useState(800); // Start width at 800px

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
        if (entry.isIntersecting) {
          setScrollPosition(0);
          setWidth(800);
        }
      },
      {
        threshold: 0,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref]);

  useEffect(() => {
    const handleScroll = () => {
      if (isVisible && ref.current) {
        // Calculate the scroll position relative to the section
        const rect = ref.current.getBoundingClientRect(); // Get the position of the section
        const scrollTop = Math.max(
          0,
          window.scrollY - (rect.top + window.scrollY - rect.height)
        );

        setScrollPosition(scrollTop); // Update scroll position

        // Update the width based on the scroll position
        const newWidth = Math.min(800 + scrollTop, window.innerWidth); // Calculate new width
        setWidth(newWidth); // Update the width state
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isVisible]);

  return (
    <>
      <div>
        <div className="sticky top-0 z-10 mt-[50px]">
          <div
            className="flex justify-center mx-6"
            ref={ref}
            id="cafe-especialidad"
          >
            <div
              className="bg-cover bg-center"
              style={{
                backgroundImage: `url(${CoffeeBg})`,
                height: "100vh",
                width: `${width}px`,
                position: "relative",
                overflow: "hidden",
                backgroundPosition: "0px 65%",
              }}
            >
              <h2 className="bH1 z-20 absolute text-bBeige left-[25px] sm:left-[88px] mt-[150px] w-[280px] sm:w-auto ">
                ¿Por qué café de <br /> especialidad?
              </h2>
            </div>
          </div>
        </div>

        <div className="z-20 relative flex flex-col items-end mt-[200px] mr-[25px] sm:mr-[233px] h-[600px]">
          <p className="bP2  text-bBeige sm:w-[445px] ">
            Hace tiempo que la gente redescubrió el café, entendiendo que hay
            otra forma de consumirlo. El de especialidad no es un tipo o
            variedad de café especifico, sino que es una nueva forma de
            entenderlo, vivirlo y disfrutarlo.
          </p>
          <br />
          <br />
          <p className="bP2  text-bBeige sm:w-[445px] ">
            El café de especialidad es una filosofía que valora todo el proceso,
            desde los trabajadores en la finca hasta el barista que prepara la
            bebida.
          </p>
          <br />
          <br />
          <p className="bP2  text-bBeige sm:w-[445px] ">
            Borja nace en 2020 con el objetivo de ofrecer buen café y de hacer
            honor a esta nueva cultura, en donde la experiencia es totalmente
            superadora a lo que hasta ahora conocíamos. Nuestro objetivo
            principal es brindar un café de altísima calidad con un menú acorde
            que acompañe y complemente cada una de las sensaciones.
          </p>
        </div>
      </div>
    </>
  );
};

export default CoffeeAbout;
