import React, { useRef, useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import Image1 from "../Assets/ImageSection/Image-1.png";
import Image2 from "../Assets/ImageSection/Image-2.png";
import Image3 from "../Assets/ImageSection/Image-2.jpg";
import Image4 from "../Assets/ImageSection/Image-4.jpg";
import Image5 from "../Assets/ImageSection/Image-5.png";
import Image6 from "../Assets/ImageSection/Image-6.png";

const ImageReveal = ({ src, className, backgroundColor }) => {
  const ref = useRef(null);
  const [elementTop, setElementTop] = useState(0);
  const [elementHeight, setElementHeight] = useState(0);
  const { scrollY } = useScroll();

  useEffect(() => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setElementTop(rect.top + window.scrollY); // posición desde la parte superior del documento
      setElementHeight(rect.height); // altura del elemento
    }
  }, []);

  // Efecto de traducción ajustado para detenerse al 65% del viewport
  const translateY = useTransform(
    scrollY,
    [elementTop - window.innerHeight, elementTop - window.innerHeight * 0.35],
    ["100%", "0%"] // La imagen se mueve hacia arriba mientras se hace scroll
  );

  return (
    <div className={` overflow-hidden ${className}`} ref={ref}>
      {/* Div de fondo que siempre será visible */}
      <div className="absolute inset-0" style={{ backgroundColor }} />
      <motion.img
        src={src}
        alt=""
        className="absolute inset-0 w-full h-full object-cover"
        style={{ translateY }} // Aplicar el movimiento hacia arriba con scroll
      />
    </div>
  );
};

const ImagesSection = () => {
  return (
    <div className="mx-[20px] xl:mx-auto mt-[200px]">
      <div className="flex flex-row h-[680px] lg:h-[1045px] relative max-w-[1512px] xxl:mx-[100px] mb-[-140px] lg:mb-[60px] z-[100] med:mx-14 ">
        <ImageReveal
          src={Image1}
          className="top-0 lg:w-[525px] lg:h-[711px] md:w-[270px] w-[212px] h-[287px] absolute"
          backgroundColor="#EE6325"
        />

        <ImageReveal
          src={Image3}
          className="hidden lg:block bottom-0 left-[13%] lg:left-[50%] lg:w-[312px] lg:h-[330px] md:w-[240px] w-[143px] h-[152px] absolute"
          backgroundColor="#76CAE2"
        />
        <ImageReveal
          src={Image2}
          className="top-[47%] right-[30px] lg:top-[30%] lg:right-0 lg:w-[312px] lg:h-[390px] md:w-[240px] w-[143px] h-[178px] absolute"
          backgroundColor="#F0C90B"
        />
      </div>

      <div className="flex flex-row h-[680px] lg:h-[1045px] relative max-w-[1512px] xxl:mx-[100px] med:mx-14 mb-[-340px] lg:mb-[0]">
        <ImageReveal
          src={Image4}
          className="top-0 lg:w-[606px] lg:h-[390px] md:w-[400px] w-[268px] h-[166px] absolute"
          backgroundColor="#EE6325"
        />
        <ImageReveal
          src={Image6}
          className=" hidden lg:block left-[13%] bottom-[10px] lg:bottom-[130px] lg:left-[20%] xxl:left-[30%] lg:w-[275px] lg:h-[412px] md:w-[235px] w-[188px] h-[281px] absolute"
          backgroundColor="#76CAE2"
        />
        <ImageReveal
          src={Image5}
          className="absolute   top-[29%] xl:top-[30%]  right-0 md:w-[340px] lg:w-[400px] lg:h-[267px]  w-[241px] h-[160px] "
          backgroundColor="#F0C90B"
        />
      </div>
    </div>
  );
};

export default ImagesSection;
