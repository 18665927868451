import "./Styles/App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Pruebas from "./Components/Pruebas";
import Lenis from "lenis";
import LoaderBorjaIcon from "./Assets/Icons/borja-rounded.svg";
import { ReactSVG } from "react-svg";
function App() {
  const lenisInstance = new Lenis();

  function raf(time) {
    lenisInstance.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pruebas" element={<Pruebas />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
