import React from "react";
import menuCategories from "../Hooks/MenuData.js";

const MenuMob = () => {
  return (
    <>
      <div className="pt-[90px] mb-6" id="menu-section">
        <h2 className="bH1 ml-6">Menú</h2>
        <a
          href="/#menu-section"
          target="_blank"
          rel="noopener noreferrer"
          className="ml-6 text-xl underline"
        >
          Descargá nuestro menú
        </a>
      </div>

      <div>
        {Object.entries(menuCategories).map(([category, items]) => (
          <div key={category}>
            <h2 className="text-2xl font-normal mb-4 mx-6">{category}</h2>
            {items
              .filter((item) => item.mobileVisibility === "true") // Filtrar solo los visibles
              .map((item, index) => {
                let imageClass;
                let divClass;
                let descClass;

                switch (index % 4) {
                  case 0:
                    imageClass = "first-image-mob";
                    divClass = "flex flex-col items-start";
                    descClass = "first-desc";
                    break;
                  case 1:
                    imageClass = "second-image-mob";
                    divClass = "flex flex-col items-end";
                    descClass = "second-desc";
                    break;
                  case 2:
                    imageClass = "third-image-mob";
                    divClass = "flex flex-col items-start";
                    descClass = "third-desc";
                    break;
                  case 3:
                    imageClass = "fourth-image-mob";
                    divClass = "flex flex-col items-center";
                    descClass = "fourth-desc";
                    break;
                  case 4:
                    imageClass = "fifth-image-mob";
                    divClass = "flex flex-col items-end";
                    descClass = "fifth-desc";
                    break;
                  case 5:
                    imageClass = "sixth-image-mob";
                    divClass = "sixth-div";
                    descClass = "sixth-desc";
                    break;
                  default:
                    imageClass = ""; // Por si acaso
                    divClass = "";
                    descClass = "";
                }

                return (
                  <div key={index} className="mx-6 ">
                    <div className={divClass}>
                      <div
                        className={`h-[] bg-cover bg-center  ${imageClass}`}
                        style={{ backgroundImage: `url(${item?.image})` }}
                      ></div>
                      <p className={`mb-[70px] mt-4`}>{item.subtitle}</p>
                    </div>
                  </div>
                );
              })}
          </div>
        ))}
      </div>
    </>
  );
};

export default MenuMob;
