import { ReactSVG } from "react-svg";
import Logo from "../Assets/Icons/borja-rounded.svg";
import { useState, useEffect } from "react";
import LogoIcon from "../Assets/Icons/Icon_borja.svg";

function Loader({ isVideoLoaded }) {
  const [fadeOut, setFadeOut] = useState(false);

  const isMobile = window.innerWidth <= 770;
  let auto = isMobile ? "220px" : "auto";
  let iconWidth = "137px";
  let MTop = isMobile ? "0" : "0px";

  useEffect(() => {
    document.body.style.overflow = "hidden";

    // Cuando el video esté cargado, iniciamos el fade out
    if (isVideoLoaded) {
      const timer = setTimeout(() => {
        setFadeOut(true);
      }, 1800);

      return () => clearTimeout(timer);
    }
  }, [isVideoLoaded]);

  // Temporizador para forzar el fade out después de 10 segundos, incluso si el video no se ha cargado
  useEffect(() => {
    const timeout = setTimeout(() => {
      setFadeOut(true); // Forzamos el fade-out después de 10 segundos
    }, 10000); // 10 segundos

    return () => clearTimeout(timeout); // Limpiamos el temporizador si el componente se desmonta o si el video se carga antes
  }, []); // Este efecto solo se ejecuta una vez cuando el componente se monta

  useEffect(() => {
    if (fadeOut) {
      const transitionTimer = setTimeout(() => {
        document.body.style.overflow = "auto"; // Rehabilitamos el scroll
      }, 1800);

      return () => clearTimeout(transitionTimer);
    }
  }, [fadeOut]);

  return (
    <div className={`loader ${fadeOut ? "fade-out" : ""} none-cursor`}>
      <ReactSVG
        src={Logo}
        style={{ marginTop: MTop }}
        className="App-logo"
        beforeInjection={(svg) => {
          svg.setAttribute("style", `width: ${auto}`);
          svg.classList.add("svg-class-name");
        }}
      />
      <ReactSVG
        src={LogoIcon}
        beforeInjection={(svg) => {
          svg.setAttribute(
            "style",
            `width: ${iconWidth}; height: ${iconWidth}`
          );
          svg.classList.add("svg-class-name");
        }}
        className="absolute"
      />
    </div>
  );
}

export default Loader;
