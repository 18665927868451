import React from "react";
import locations from "../Hooks/locationsData";

const LocationsMob = () => {
  return (
    <>
      <div className="pt-[90px]" id="donde-estamos">
        <h2 className="bH1 ml-6">Dónde estamos</h2>
        {Object.entries(locations).map(([key, location]) => (
          <div className="mx-8 my-11 max-w-[342px]">
            <h3
              className="text-2xl font-normal mb-4 border-l-4 border-black pl-10
"
            >
              {key}
            </h3>
            <div
              className="h-[342px] bg-cover bg-top mb-4"
              style={{ backgroundImage: `url(${location?.image})` }}
            ></div>
            <div className="">
              <p className="text-base leading-6	 font-normal mb-2">
                {location?.locationText}
              </p>
              <a
                href={location?.locationLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-base leading-6	"
              >
                Ver en Google Maps
              </a>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default LocationsMob;
