import React from "react";
import StaggeredText from "../Hooks/StaggeredText";

const Pruebas = () => {
  return (
    <>
      <div className="h-[100vh]">
        <StaggeredText text={"PRUEBAS"} />
      </div>
    </>
  );
};

export default Pruebas;
